<template>
  <div>
    <validation-observer ref="validateAd">
      <b-form>
        <b-modal
          dialog-class="ad-modal-dialog"
          content-class="ad-modal-content"
          v-model="adModal"
          scrollable
          size="sm"
          ref="adModal"
          @hide="toggleAdDialog(false)"
        >
          <template #modal-title>
            <strong>{{ id == 0 ? "إضافة إعلان" : "تعديل الإعلان" }} </strong>
          </template>
          <b-form-group label="العنوان الرئيسي" label-for="AdName">
            <validation-provider
              #default="{ errors }"
              name="العنوان الرئيسي"
              rules="required"
            >
              <b-form-input
                id="AdName"
                v-model="adForm.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="تاريخ بداية الإعلان" label-for="adDates">
            <validation-provider
              #default="{ errors }"
              name="تاريخ بداية الإعلان"
              rules="required"
            >
              <flat-pickr
                v-model="adForm.startDate"
                class="form-control"
                :config="config"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="تاريخ نهاية الإعلان" label-for="adDates">
            <validation-provider
              #default="{ errors }"
              name="تاريخ نهاية الإعلان"
              rules="required"
            >
              <flat-pickr
                v-model="adForm.endDate"
                class="form-control"
                :config="config"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="الصورة" label-for="AdImage">
            <fileDragDrop
              @inValidFile="inValiAdImage"
              @uploaded="uploadAdImage"
              id="AdImage"
              height="100px"
              type="image"
              title="أدرج الصورة هنا أو انقر للتحميل"
            />
            <img
              :src="
                !adForm.imageDto && !adForm.imagePath
                  ? '/media/placeholder/image-placehoder.jpg'
                  : !!adForm.imageDto
                  ? adForm.imageDto.base64
                  : adForm.imagePath
              "
              style="max-height: 320px; object-fit: fill"
              class="w-100 my-2"
            />
          </b-form-group>
          <template #modal-footer>
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitSetAd()"
            >
              {{ id == 0 ? "إضافة" : "تعديل" }}
            </b-button>
            <b-button @click="toggleAdDialog(false)" size="md" variant="outline-primary">
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>
.ad-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.ad-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormGroup, BFormInput, BForm } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
    fileDragDrop,
  },
  data: () => ({
    adModal: false,
    required,
    config: {
      allowInput: true,
    },
  }),
  mounted() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isAdDialog", "adForm", "id"]),
  },
  methods: {
    ...mapActions(["toggleAdDialog", "fetchAdForm", "setAd", "clearAdForm"]),
    SubmitSetAd() {
      this.$refs.validateAd.validate().then((success) => {
        if (success) {
          this.setAd(this.adForm).then(() => {
            if (this.adForm.id == 0) {
              this.$bvToast.toast(`تم إضافة الإعلان بنجاح`, {
                title: "تم الإضافة",
                variant: "success",
                toaster: "b-toaster-bottom-left",
                solid: true,
                autoHideDelay: 2000,
                appendToast: true,
              });
            } else {
              this.$bvToast.toast(`تم تعديل الإعلان بنجاح`, {
                title: "تم التعديل",
                variant: "success",
                toaster: "b-toaster-bottom-left",
                solid: true,
                autoHideDelay: 2000,
                appendToast: true,
              });
            }
          });
          this.$refs.adModal.hide();
        }
      });
    },
    uploadAdImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (() => {
        Object.assign(this.adForm.imageDto, {
          base64: reader.result,
          contentType: file.type,
          fileName: file.name,
          length: file.size,
        });
      }).bind(this);
    },
    inValiAdImage() {
      console.log("invalid file type");
    },
  },
  watch: {
    isAdDialog(newVal) {
      this.adModal = newVal;
    },
    id(newId) {
      if (newId != 0) {
        this.fetchAdForm(newId);
      } else {
        this.clearAdForm();
      }
    },
  },
};
</script>
