<template>
  <div>
    <b-row v-if="mainAdsList.length > 0">
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mx-auto"
        v-for="(ad, index) in adsActivePage"
        :key="index"
      >
        <b-card img-top class="mb-3 cardShadow" no-body>
          <b-card-img
            :src="ad.imagePath == null ? '/media/placeholder/image-placehoder.jpg' : ad.imagePath"
            class="w-100"
            style="border-radius: 0 !important"
          ></b-card-img>
          <b-card-header class="py-1">
            <strong>{{ ad.title }}</strong>
          </b-card-header>
          <b-card-body class="pt-0">
            <b-card-text>
              تاريخ البداية : {{ new Date(ad.startDate).toISOString().substr(0, 10) }}
            </b-card-text>
            <b-card-text>
              تاريخ النهاية : {{ new Date(ad.endDate).toISOString().substr(0, 10) }}
            </b-card-text>
          </b-card-body>

          <div>
            <b-button
              size="md"
              variant="primary"
              class="w-50"
              style="border-radius: 0 0 0.428rem 0 !important"
              @click="
                setId(ad.id);
                toggleAdDialog(true);
              "
            >
              تعديل
            </b-button>
            <b-button
              size="md"
              variant="danger"
              class="w-50"
              style="border-radius: 0 0 0 0.428rem !important"
              @click="SubmitRemoveAd(ad.id)"
            >
              حذف
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          :value="adsFirstPage"
          :total-rows="mainAdsList.length"
          :per-page="adPageLength"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => adsPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row v-else class="justify-content-center mt-2">
      <h4>لا توجد إعلانات متاحة</h4>
    </b-row>
    <adDialog />
  </div>
</template>

<style scoped>
.cardShadow {
  max-width: 20rem;
}
.isFreeBadge {
  position: absolute;
  right: 15px;
  top: 11px;
  padding: 8px;
  opacity: 0.9;
}
</style>

<script>
import store from "@/store";
import adDialog from "./components/adDialog";
import Swal from "sweetalert2";
import {
  BPagination,
  BCard,
  BCardText,
  BButton,
  BCardImg,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    adDialog,
    BPagination,
    BCard,
    BCardText,
    BButton,
    BCardImg,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
  },
  data: () => ({
  }),
  mounted() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "إعلان جديد",
      fetchingFunction: this.toggleAdDialog,
      placeHolder: "ابحث عن إعلان محدد",
      value: "",
      filterFunc: this.filterAds,
    });
    this.fetchAds();
  },
  computed: {
    ...mapGetters(["mainAdsList", "adsFirstPage", "adsActivePage", "adPageLength"]),
  },
  methods: {
    ...mapActions(["fetchAds", "toggleAdDialog", "setId", "adsPagination", "removeAd", "filterAds"]),
    SubmitRemoveAd(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الإعلان ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removeAd(id);
          }
        });
    },
  },
};
</script>
